 function formatDate (apiDateStr) {
    if(!apiDateStr){
        return apiDateStr;
    }
    let rowDate = new Date(apiDateStr);
    let date =
      rowDate.getFullYear() +
      "-" +
      (rowDate.getMonth() + 1) +
      "-" +
      rowDate.getDate() +
      " " +
      rowDate.getHours() +
      ":" +
      rowDate.getMinutes() +
      ":" +
      rowDate.getSeconds();
    return date;
  }

  export default {formatDate};