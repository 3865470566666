<template>
  <div id="app">
    <el-container>
      <el-header>
        <NevMenu></NevMenu>
      </el-header>
      <el-main>
        <router-view></router-view>
      </el-main>
      <el-footer class="bottom">
          工信部备案号：<a href="https://beian.miit.gov.cn">粤ICP备2021137980号-1</a>
      </el-footer>
    </el-container>
  </div>
</template>
<script>
import NevMenu from './components/page-framwork/NevMenu';
export default {
  name: "zwb-root",
  components:{
    NevMenu
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 10px;
}

.bottom {
  /* position: absolute; */
  bottom: 0;
  font-size: 12px;
  /* margin: auto; */
  width: 90%;
  /* height: 10px; 脚部的高度 */
  /* background: #6cf; */
  clear: both;
}

.level-1-title {
  font-size: 20px;
}
</style>
