<template>
  <div>
    <el-row :gutter="10">
      <el-col :span="20">
        <div class="grid-content" @keyup.enter="onAddTodo">
          <el-input v-model="input" placeholder="请输入待办内容,按下回车保存" ></el-input>
        </div>
      </el-col>
    </el-row>

    <el-row type="flex">
      <el-col :span="23">
        <el-table :data="todos" style="width: 100%">
          <el-table-column prop="title" label="内容"> </el-table-column>
          <el-table-column prop="createDate" label="开始时间">
            <template slot-scope="scope">
              {{ formatDate(scope.row.createDate) }}
            </template>
          </el-table-column>
          <el-table-column prop="status" label="耗时(H)">
            <template slot-scope="scope">
              {{ countTime(scope.$index) }}
            </template>
          </el-table-column>
          <!-- <el-table-column prop="status" label="状态"> </el-table-column> -->
          <el-table-column prop="status" label="操作">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="handleEdit(scope.$index)" :disabled="scope.row.status == 1">
                完成</el-button>
              <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        
        <el-col :span="8"> </el-col>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "HelloWorld",
  props: {
    loginUserName: String,
  },
  data() {
    return {
      input: "",
      todos: [],
      current:1,
      size:20,
      total:0
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      axios.get("/api/todo/v1/list").then((resp) => {
        if (resp.data.code == 0) {
          this.todos = resp.data.data;
        } else {
          this.$message.error("error:[" + resp.data.code + "]" + resp.data.msg);
        }
      }).catch(e => {
        if (e.response && e.response.status == 401) {
          this.$message.error("This menu is private,please login")
          this.$parent.$parent.$parent.clickLogin()
          this.$refs.loginLink.click()
        } else {
          this.$message.error("system error:" + e);
        }
        // this.dialogVisible=true;
      });
      this.input = "";
    },
    onAddTodo: function () {
      let todo = {};
      if (!this.input) {
        return;
      }
      todo.title = this.input;

      axios.post("/api/todo/v1/add", todo).then((resp) => {
        if (resp.data.code != 0) {
          this.$message.error("错误:" + resp.data.code);
        }
        this.loadData();
      });

    },
    formatDate: function (apiDateStr) {
      let rowDate = new Date(apiDateStr);
      let date =
        rowDate.getFullYear() +
        "-" +
        (rowDate.getMonth() + 1) +
        "-" +
        rowDate.getDate() +
        " " +
        rowDate.getHours() +
        ":" +
        rowDate.getMinutes() +
        ":" +
        rowDate.getSeconds();
      return date;
    },
    handleEdit: function (index) {
      let todo = this.todos[index];
      let param = { id: todo.id, status: 1 };
      axios.put("/api/todo/v1/edit", param).then((resp) => {
        if (resp.data.code != 0) {
          this.$message.error("错误:" + resp.data.code);
        }
        this.loadData();
      });
    },
    handleDelete: function (index) {
      let todo = this.todos[index];
      let param = { id: todo.id, deleted: 1 };
      axios.put("/api/todo/v1/edit", param).then((resp) => {
        if (resp.data.code != 0) {
          this.$message.error("错误:" + resp.data.code);
        }
        this.loadData();
      });
    },
    handleResp: function (resp) {
      if (resp.data.code == 0) {
        return resp.data.data;
      } else {
        this.$message.error("错误:" + resp.data.code);
        return null;
      }
    },
    countTime: function (index) {
      let data = this.todos[index];
      if (!data.modifyDate) {
        return "";
      }
      let startDate = new Date(data.createDate);
      let endDate = new Date(data.modifyDate)
      let hours = (endDate.getTime() - startDate) / 3600000
      return hours.toFixed(2);
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #d3dce6;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
</style>