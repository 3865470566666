<template>
  <div>
    <el-row :gutter="10">
      <el-col :span="6">
        <el-input v-model="title" placeholder="input blog title to search"></el-input>
      </el-col>
      <el-col :span="1">
        <el-button type="primary" @click="onSearchBlog" plain>search</el-button>
      </el-col>
      <el-col :span="15">
        &nbsp;
      </el-col>
      <el-col :span="2" v-if="isAdmin">
        <el-button type="success" @click="handleAdd" plain>add</el-button>
      </el-col>
    </el-row>

    <el-row type="flex">
      <el-col :span="23">
        <el-table :data="blogs" style="width: 100%">
          <el-table-column prop="title" label="title" width="1300px">
            <template slot-scope="scope">
              <el-link type="primary" @click="handleEdit(scope.row)">{{ scope.row.title }}</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="createDate" label="create date" v-if="isAdmin">
            <template slot-scope="scope">
              {{ formatDate(scope.row.createDate) }}
            </template>
          </el-table-column>
          <el-table-column prop="modifyDate" label="modify date" v-if="isAdmin">
            <template slot-scope="scope">
              {{ formatDate(scope.row.modifyDate) }}
            </template>
          </el-table-column>
          <el-table-column label="operation" v-if="isAdmin">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="handleEdit(scope.row)" plain>detail</el-button>
              <el-button size="mini" type="warning" @click="handleDelete(scope.row)" plain>delete</el-button>
            </template>
          </el-table-column>
        </el-table>

      </el-col>
    </el-row>
    <el-row type="flex" justify="end">
      <div class="block">
        <el-pagination @size-change="onSearchBlog" @current-change="onSearchBlog" :current-page.sync="current"
          :page-sizes="[20, 50, 100, 500]" :page-size.sync="size" layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </el-row>
    <el-dialog title="Add blog" :visible.sync="addDialogVisible" width="30%">
      <div>
        <el-row>
          <el-col :span="10">
            <span>
              Title
            </span>
          </el-col>
          <el-col :span="10">
            <el-input v-model="toAddTitle" placeholder="please input title"></el-input>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <span>
              Content
            </span>
          </el-col>
          <el-col :span="10">
            <el-input v-model="toAddContent" placeholder="please input content"></el-input>
          </el-col>
        </el-row>
        <el-row>
          &nbsp;
        </el-row>
        <span slot="footer" class="dialog-footer">
          <el-button type="info" @click="addDialogVisible = false">cancel</el-button>
          <el-button type="primary" @click="onAddBlog">save</el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog title="Edit blog" :visible.sync="editDialogVisible" width="30%">
      <div>
        <el-row>
          <el-col :span="10">
            <span>
              Title
            </span>
          </el-col>
          <el-col :span="10">
            <el-input v-model="toEditTitle" placeholder="please input title"></el-input>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <span>
              Content
            </span>
          </el-col>
          <el-col :span="10">
            <el-input v-model="toEditContent" placeholder="please input content"></el-input>
          </el-col>
        </el-row>
        <el-row>
          &nbsp;
        </el-row>
        <span slot="footer" class="dialog-footer">
          <el-button type="info" @click="editDialogVisible = false">cancel</el-button>
          <el-button type="primary" @click="onEditBlog">save</el-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import DateUtils from "../js/DateUtils"
export default {
  name: "UserManage",
  data() {
    return {
      title: "",
      blogs: [],
      current: 1,
      size: 20,
      total: 0,
      addDialogVisible: false,
      editDialogVisible: false,
      toAddTitle: "",
      toAddContent: "",
      toEditTitle: "",
      toEditContent: "",
      toEditId: null,
      isAdmin: false
    };
  },
  computed: {
    queryParam() {
      return { current: this.current, size: this.size, title: this.title }
    }
  },
  mounted() {
    this.onSearchBlog()
  },
  methods: {
    onSearchBlog() {
      this.loadData(this.queryParam);
    },
    loadData(reqData) {
      axios.post("/api/blog/v1/query", reqData).then((resp) => {
        if (resp.data.code == 0) {
          const result = resp.data.data;
          this.blogs = result.records
          this.total = result.total
        } else {
          this.$message.error("error:[" + resp.data.code + "]" + resp.data.msg);
        }
      }).catch(e => {
        if (e.response && e.response.status == 401) {
          this.$message.error("This menu is private,please login")
          this.$parent.$parent.$parent.clickLogin()
        } else {
          this.$message.error("system error:" + e);
        }
      });
      const name = window.localStorage.getItem("userName")
      console.log('name is' + name)
      if (name == "wenbin.zheng") {
        this.isAdmin = true;
      }
    },
    onAddBlog: function () {
      axios.post("/api/blog/v1/add", { title: this.toAddTitle, content: this.toAddContent }).then((resp) => {
        if (resp.data.code == 0) {
          this.onSearchBlog()
          this.toAddTitle = ""
          this.toAddContent = ""
          this.addDialogVisible = false
          this.$message.success("add blog success");

        } else {
          this.$message.error("error:[" + resp.data.code + "]" + resp.data.msg);
        }
      }).catch(e => {
        if (e.response && e.response.status == 401) {
          this.$message.error("This menu is private,please login")
          this.$parent.$parent.$parent.clickLogin()
        } else {
          this.$message.error("system error:" + e);
        }
      })
    },
    onEditBlog: function () {
      axios.post("/api/blog/v1/update", { title: this.toEditTitle, content: this.toEditContent, id: this.toEditId }).then((resp) => {
        if (resp.data.code == 0) {
          this.onSearchBlog()
          this.toEditTitle = ""
          this.toEditContent = ""
          this.editDialogVisible = false
          this.$message.success("edit blog success");

        } else {
          this.$message.error("error:[" + resp.data.code + "]" + resp.data.msg);
        }
      }).catch(e => {
        if (e.response && e.response.status == 401) {
          this.$message.error("This menu is private,please login")
          this.$parent.$parent.$parent.clickLogin()
        } else {
          this.$message.error("system error:" + e);
        }
      })
    },
    handleDelete: function (row) {
      const blogId = row.id
      axios.delete("/api/blog/v1/delete?id=" + blogId).then((resp) => {
        if (resp.data.code == 0) {
          this.onSearchBlog()
          this.$message.success("delete blog success");

        } else {
          this.$message.error("error:[" + resp.data.code + "]" + resp.data.msg);
        }
      }).catch(e => {
        if (e.response && e.response.status == 401) {
          this.$message.error("This menu is private,please login")
          this.$parent.$parent.$parent.clickLogin()
          this.$refs.loginLink.click()
        } else {
          this.$message.error("system error:" + e);
        }
      });

    },
    handleEdit: function (row) {
      this.$router.push("/blogDetail?blogId=" + row.id);
    },
    handleAdd: function () {
      this.$router.push("/blogDetail");
    },
    formatDate: function (apiDateStr) {

      return DateUtils.formatDate(apiDateStr);
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #d3dce6;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
</style>