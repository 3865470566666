import Vue from 'vue'
import App from './App.vue'
import Home from './components/Home.vue'
import Todo from './components/Todo.vue'
import User from './components/User.vue'
import NginxUser from './components/NginxUser.vue'
import Video from './components/Video.vue'
import Blog from './components/Blog.vue'
import BlogDetail from './components/BlogDetail.vue'
import Dict from './components/Dict.vue'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
import VueRouter from 'vue-router'
import mavonEditor from 'mavon-editor';
import 'mavon-editor/dist/css/index.css';
import showdown from 'showdown';
import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'

Vue.prototype.converter = new showdown.Converter();
Vue.use(mavonEditor);
Vue.use(ElementUI, axios);
Vue.use(VueRouter);
Vue.use(VueVideoPlayer);
Vue.config.productionTip = false

const routes = [
  { path: '/home', component: Home },
  { path: '/', component: Home },
  { path: '/user', component: User },
  { path: '/nginx-user', component: NginxUser },
  { path: '/todo', component: Todo },
  { path: '/video', component: Video },
  { path: '/blog', component: Blog },
  { path: '/blogDetail', component: BlogDetail },
  { path: '/dict', component: Dict },
]

const router = new VueRouter({
  routes // (缩写) 相当于 routes: routes
})

new Vue({
  router: router,
  render: h => h(App),
}).$mount('#app')
