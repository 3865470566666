<template>
    <div>
        <el-menu class="el-menu-demo" mode="horizontal" active-text-color="#ffd04b" @select="handleSelect">
            <el-menu-item index="home-tab">
                Home
            </el-menu-item>
            <el-menu-item index="blog-tab">
                Blog
            </el-menu-item>
            <el-menu-item index="dict-tab">
                Dictionary
            </el-menu-item>
            <el-menu-item index="chat-tab">
                Chat GPT
            </el-menu-item>
            <el-menu-item index="todo-tab" v-if="loginedUser">
                Todo
            </el-menu-item>
            <el-menu-item index="user-tab" v-if="loginedUser">
                User
            </el-menu-item>
            <el-menu-item index="nginx-user-tab" v-if="loginedUser">
                Nginx User
            </el-menu-item>
            <el-menu-item index="video-tab" v-if="loginedUser">
                Video
            </el-menu-item>
            <el-submenu index="login-tab" v-if="loginedUser" class="loginMenu">
                <template slot="title">{{ loginedUser }}</template>
                <el-menu-item index="user-tab-logout-tab">
                    logout
                </el-menu-item>
            </el-submenu>
            <el-menu-item v-else index="login-tab" class="loginMenu">
                login
            </el-menu-item>
        </el-menu>
        <el-dialog title="please login" :visible.sync="dialogVisible" width="30%">
            <div>
                <el-row>
                    <el-col :span="10">
                        <span>
                            User Name
                        </span>
                    </el-col>
                    <el-col :span="10">
                        <el-input v-model="userName" placeholder="please input user name"></el-input>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="10">
                        <span>
                            Password
                        </span>
                    </el-col>
                    <el-col :span="10">
                        <el-input v-model="password" show-password placeholder="please input password"></el-input>
                    </el-col>
                </el-row>
                <el-row>
                    &nbsp;
                </el-row>
                <span slot="footer" class="dialog-footer">
                    <el-button type="info" @click="dialogVisible = false">cancel</el-button>
                    <el-button type="primary" @click="login">login</el-button>
                </span>
            </div>
        </el-dialog>
    </div>


</template>

<script>
import axios from 'axios';
export default {
    name: "zwb-page-header",
    mounted() {
        this.loadData();
    },
    data() {
        return {
            userName: "",
            password: "",
            loginedUser: "",
            dialogVisible: false
        };
    },
    methods: {
        handleSelect(key) {
            console.log(key)
            switch (key) {
                case "home-tab":
                    this.$router.push("/home")
                    break
                case "blog-tab":
                    this.$router.push("/blog")
                    break
                case "dict-tab":
                    this.$router.push("/dict")
                    break    
                case "chat-tab":
                    window.location = "https://zhengwenbin.com:3000/"
                    break
                case "todo-tab":
                    this.$router.push("/todo")
                    break
                case "nginx-user-tab":
                    this.$router.push("/nginx-user")
                    break
                case "user-tab":
                    this.$router.push("/user")
                    break
                case "video-tab":
                    this.$router.push("/video")
                    break
                case "login-tab":
                    this.clickLogin()
                    break
                case "user-tab-logout-tab":
                    this.logout()
                    break;
            }
        },
        loadData() {
            axios.get("/api/session/v1/userInfo").then((resp) => {
                if (resp.data.code == 0) {
                    let userInfo = resp.data.data;
                    this.loginedUser = userInfo.userName;
                    this.dialogVisible = false;
                    window.localStorage.setItem("userId", userInfo.userId)
                    window.localStorage.setItem("userName", userInfo.userName)
                } else {
                    window.localStorage.clear();
                    this.$message.error("error:[" + resp.data.code + "]" + resp.data.msg);
                }
            }).catch(e => {
                window.localStorage.clear();
                if (e.response && e.response.status == 401) {
                    this.loginedUser = "";
                    window.localStorage.clear();
                } else {
                    console.log(e);
                    this.$message.error("system error:" + e);
                }
            });
        },
        clickLogin() {
            this.userName = "";
            this.password = "";
            this.dialogVisible = true;
        },
        login() {
            axios.post("/api/session/v1/login", { userName: this.userName, password: this.password }).then((resp) => {
                let result = resp.data;
                if (result.code == 0) {
                    location.reload();
                }
                else {
                    this.$message.error(result.msg);
                }
            });
        },
        handleResp: function (resp) {
            if (resp.data.code == 0) {
                return resp.data.data;
            }
            else {
                this.$message.error("错误:" + resp.data.code);
                return null;
            }
        },
        logout() {
            axios.get("/api/session/v1/logout").then((resp) => {
                let result = resp.data;
                if (result.code == 0) {
                    this.loginedUser = ""
                    this.$message.success('logout success');
                    this.$router.push("/home")
                } else {
                    this.$message.error(result.msg);
                }
            });
            window.localStorage.clear();
        }
    }
}
</script>

<style>
.loginMenu {
    float: right !important;
}
</style>