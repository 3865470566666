<template>
  <div>
    <el-row :gutter="10" class="text-left">
        &nbsp;
    </el-row>
    <el-row :gutter="10" class="text-left">
        &nbsp;
    </el-row>
    <el-row :gutter="10" class="text-left">
        &nbsp;
    </el-row>
    <el-row :gutter="10" class="text-left">
        &nbsp;
    </el-row>
    <el-row :gutter="10" class="text-left">
        &nbsp;
    </el-row>
    <el-row :gutter="10" class="text-left">
        &nbsp;
    </el-row>
    <el-row :gutter="10">
      <el-autocomplete
        v-model="input"
        :fetch-suggestions="queryData"
        placeholder="请输入单词"
        @select="handleSelect"
        value-key="word"
      ></el-autocomplete>
      <!-- style="width:300px" -->
    </el-row >
    <el-row :gutter="10" class="text-left">
        &nbsp;
    </el-row>
    <el-row :gutter="10" class="text-left">
        &nbsp;
    </el-row>
    <el-row :gutter="10" class="text-left">
        <h2>{{ word }}</h2>
    </el-row>
    <el-row :gutter="10" class="text-left"> 
    <el-tag>读音</el-tag> 
    【{{ pron }}】 
    </el-row>
    <el-row :gutter="10" class="text-left">
        &nbsp;
    </el-row>
    <el-row :gutter="10" class="text-left">
      <el-tag>英文释义</el-tag>
      <ol>
        <li v-for="item in enExplain" :key="item">
          {{ item }}
        </li>
      </ol>
    </el-row>
    <el-row :gutter="10" class="text-left">
      <el-tag>中文释义</el-tag>
      <ol>
        <li v-for="item in cnExplain" :key="item">
          {{ item }}
        </li>
      </ol>
    </el-row>
    <el-row :gutter="10" class="text-left">
      <el-tag>词形</el-tag>
      <ol>
        <li v-for="item in format" :key="item">
          {{ item }}
        </li>
      </ol>
    </el-row>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "ZwbDict",
  props: {
    loginUserName: String,
  },
  data() {
    return {
      input: "",
      word: "",
      pron: "",
      enExplain: [],
      cnExplain: [],
      format: [],
    };
  },
  methods: {
    queryData(word, cb) {
      axios
        .get("/api/dict/v1/query?word=" + word)
        .then((resp) => {
          if (resp.data.code == 0) {
            cb(resp.data.data);
          } else {
            this.$message.error("error:[" + resp.data.code + "]" + resp.data.msg);
          }
        })
        .catch((e) => {
          if (e.response && e.response.status == 401) {
            this.$message.error("This menu is private,please login");
            this.$parent.$parent.$parent.clickLogin();
            this.$refs.loginLink.click();
          } else {
            this.$message.error("system error:" + e);
          }
        });
    },
    handleSelect(item) {
      this.word = item.word;
      this.pron = item.phonetic;
      this.enExplain = item.definition.split("\\n");
      this.cnExplain = item.translation.split("\\n");
      let ex = item.exchange;
      ex = ex.replace("p:", "过去式：");
      ex = ex.replace("d:", "过去分词：");
      ex = ex.replace("i:", "现在分词：");
      ex = ex.replace("3:", "第三人称单数：");
      ex = ex.replace("r:", "形容词比较级：");
      ex = ex.replace("t:", "形容词最高级：");
      ex = ex.replace("s:", "名词复数形式：");
      ex = ex.replace("0:", "词根：");
      ex = ex.replace("1:", "词根：");
      console.log(ex);
      this.format = ex.split("/");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #d3dce6;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.text-left {
  text-align: left;
}
</style>
