<template>
  <div>
    <el-row :gutter="10">
      <el-col :span="6">
        <el-input v-model="userName" placeholder="input user name to search"></el-input>
      </el-col>
      <el-col :span="1">
        <el-button type="primary" @click="onSearchUser">search</el-button>
      </el-col>
      <el-col :span="15">
        &nbsp;
      </el-col>
      <el-col :span="2">
        <el-button type="success" @click="dialogVisible = true">add</el-button>
      </el-col>
    </el-row>

    <el-row type="flex">
      <el-col :span="23">
        <el-table :data="users" style="width: 100%">
          <el-table-column prop="id" label="id"> </el-table-column>
          <el-table-column prop="userName" label="name"> </el-table-column>
          <el-table-column prop="createDate" label="create date">
            <template slot-scope="scope">
              {{ formatDate(scope.row.createDate) }}
            </template>
          </el-table-column>
          <el-table-column prop="status" label="操作">
            <template slot-scope="scope">
              <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>

        <el-col :span="8"> </el-col>
      </el-col>
    </el-row>
    <el-row type="flex" justify="end">
      <div class="block">
        <el-pagination @size-change="onSearchUser" @current-change="onSearchUser" :current-page.sync="current"
          :page-sizes="[20, 50, 100, 500]" :page-size.sync="size" layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </el-row>
    <el-dialog title="Add User" :visible.sync="dialogVisible" width="30%">
      <div>
        <el-row>
          <el-col :span="10">
            <span>
              User Name
            </span>
          </el-col>
          <el-col :span="10">
            <el-input v-model="toAddUserName" placeholder="please input user name"></el-input>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <span>
              Password
            </span>
          </el-col>
          <el-col :span="10">
            <el-input v-model="toAddUserPassword" show-password placeholder="please input password"></el-input>
          </el-col>
        </el-row>
        <el-row>
          &nbsp;
        </el-row>
        <span slot="footer" class="dialog-footer">
          <el-button type="info" @click="dialogVisible = false">cancel</el-button>
          <el-button type="primary" @click="onAddUser">save</el-button>
        </span>
      </div>

    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "UserManage",
  data() {
    return {
      userName: "",
      users: [],
      current: 1,
      size: 20,
      total: 0,
      dialogVisible: false,
      toAddUserName: "",
      toAddUserPassword: ""
    };
  },
  computed: {
    queryParam() {
      return { current: this.current, size: this.size ,userName:this.userName}
    }
  },
  mounted() {
    this.onSearchUser()
  },
  methods: {
    onSearchUser() {
      this.loadData(this.queryParam);
    },
    loadData(reqData) {
      axios.post("/api/user/v1/query", reqData).then((resp) => {
        if (resp.data.code == 0) {
          const result = resp.data.data;
          this.users = result.records
          this.total = result.total
        } else {
          this.$message.error("error:[" + resp.data.code + "]" + resp.data.msg);
        }
      }).catch(e => {
        if (e.response && e.response.status == 401) {
          this.$message.error("This menu is private,please login")
          this.$parent.$parent.$parent.clickLogin()
        } else {
          this.$message.error("system error:" + e);
        }
      });
    },
    onAddUser: function () {
      axios.post("/api/user/v1/add",{userName:this.toAddUserName,password:this.toAddUserPassword}).then((resp) => {
        if (resp.data.code == 0) {
          this.onSearchUser()
          this.toAddUserName=""
          this.toAddUserPassword=""
          this.dialogVisible=false
          this.$message.success("add user success");

        } else {
          this.$message.error("error:[" + resp.data.code + "]" + resp.data.msg);
        }
      }).catch(e => {
        if (e.response && e.response.status == 401) {
          this.$message.error("This menu is private,please login")
          this.$parent.$parent.$parent.clickLogin()
        } else {
          this.$message.error("system error:" + e);
        }
      })
    },
    handleDelete: function (index, row) {
      const userId = row.id
      axios.delete("/api/user/v1/delete?id=" + userId).then((resp) => {
        if (resp.data.code == 0) {
          this.onSearchUser()
          this.$message.success("delete user success");

        } else {
          this.$message.error("error:[" + resp.data.code + "]" + resp.data.msg);
        }
      }).catch(e => {
        if (e.response && e.response.status == 401) {
          this.$message.error("This menu is private,please login")
          this.$parent.$parent.$parent.clickLogin()
          this.$refs.loginLink.click()
        } else {
          this.$message.error("system error:" + e);
        }
      });

    },
    handleResp: function (resp) {
      if (resp.data.code == 0) {
        return resp.data.data;
      } else {
        this.$message.error("错误:" + resp.data.code);
        return null;
      }
    },
    formatDate: function (apiDateStr) {
      let rowDate = new Date(apiDateStr);
      let date =
        rowDate.getFullYear() +
        "-" +
        (rowDate.getMonth() + 1) +
        "-" +
        rowDate.getDate() +
        " " +
        rowDate.getHours() +
        ":" +
        rowDate.getMinutes() +
        ":" +
        rowDate.getSeconds();
      return date;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #d3dce6;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
</style>