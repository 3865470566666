<template>
  <div>
    <div v-if="editFlag">
      <el-row type="flex" justify="end">
        <el-button type="primary" @click="sendBolg" style="{float:right}" plain>save</el-button>
      </el-row>
      <mavon-editor v-model="homeBlogContent" :toolbars="toolbars" :subfield="true" />
    </div>
    <div v-if="!editFlag">
      <el-row type="flex" justify="end" v-if="isAdmin">
        <el-button type="primary" @click="editFlag = true" plain>edit</el-button>
      </el-row>
      <el-row>
        <mavon-editor v-model="homeBlogContent" :toolbarsFlag="false" :subfield="false" defaultOpen="preview"
          :editable="false" />
      </el-row>
      <el-row>&nbsp;</el-row>
      <el-row>&nbsp;</el-row>
      <el-row>
        <div class="grid-content" @keyup.enter="onAddComment">
          <el-input autosize placeholder="leave a comment?" v-model="toAddComment" >
            <el-button slot="append" icon="el-icon-check" @click="onAddComment"></el-button>
          </el-input>
        </div>
      </el-row>
      <el-row>
        <el-table :data="comments" style="width: 100%">
          <template slot="empty">
            <el-empty :image-size="100" description='there is no comment'></el-empty>
          </template>
          <el-table-column prop="createDate">
            <template slot-scope="scope">
              {{ scope.row.comment }}
              <el-link type="info" @click="handleDelete(scope.row)">delete</el-link>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import DateUtils from "../js/DateUtils"
export default {
  name: "HomePage",
  data() {
    return {
      editData: '',//输入的数据
      viewData: '',//输入的数据
      homeBlogContent: '',
      rawHtml: '',
      editFlag: false,
      isAdmin: false,
      comments: [],
      toAddComment: null,
      toolbars: {
        bold: true, // 粗体
        italic: true, // 斜体
        header: true, // 标题
        underline: true, // 下划线
        mark: true, // 标记
        superscript: true, // 上角标
        quote: true, // 引用
        ol: true, // 有序列表
        link: true, // 链接
        imagelink: true, // 图片链接
        help: true, // 帮助
        code: true, // code
        subfield: true, // 是否需要分栏
        fullscreen: true, // 全屏编辑
        readmodel: true, // 沉浸式阅读
        undo: true, // 上一步
        trash: true, // 清空
        save: true, // 保存（触发events中的save事件）
        navigation: true // 导航目录
      }
    };
  },
  mounted() {
    const name = window.localStorage.getItem("userName")
    if (name == "wenbin.zheng") {
      this.isAdmin = true;
    }
    this.loadData()
  },
  methods: {
    sendBolg() {
      console.log(this.editData)
      this.viewData = this.editData
      this.editFlag = false
      axios.post("/api/blog/v1/saveHomeBlog", { content: this.homeBlogContent }).then((resp) => {
        if (resp.data.code != 0) {
          this.$message.error("error:[" + resp.data.code + "]" + resp.data.msg);
        }
      }).catch(e => {
        console.log(e);
        if (e.response && e.response.status == 401) {
          this.$message.error("This menu is private,please login")
          this.$parent.$parent.$parent.clickLogin()
          this.$refs.loginLink.click()
        } else {
          this.$message.error("system error:" + e);
        }
        // this.dialogVisible=true;
      });

    },
    loadData() {
      axios.get("/api/blog/v1/detailOfHomeBlog").then((resp) => {
        if (resp.data.code == 0) {
          this.homeBlogContent = resp.data.data.content;
        } else {
          this.$message.error("error:[" + resp.data.code + "]" + resp.data.msg);
        }
      }).catch(e => {
        console.log(e);
        if (e.response && e.response.status == 401) {
          this.$message.error("This menu is private,please login")
          this.$parent.$parent.$parent.clickLogin()
          this.$refs.loginLink.click()
        } else {
          this.$message.error("system error:" + e);
        }
        // this.dialogVisible=true;
      });
      this.queryComment();
    },
    queryComment() {
      axios.get("/api/comment/v1/queryHomeComment").then((resp) => {
        if (resp.data.code == 0) {
          this.comments = resp.data.data;
        } else {
          this.$message.error("error:[" + resp.data.code + "]" + resp.data.msg);
        }
      }).catch(e => {
        console.log(e);
        if (e.response && e.response.status == 401) {
          this.$message.error("This menu is private,please login")
          this.$parent.$parent.$parent.clickLogin()
          this.$refs.loginLink.click()
        } else {
          this.$message.error("system error:" + e);
        }
        // this.dialogVisible=true;
      });
    },
    onAddComment() {
      axios.post("/api/comment/v1/addHomePageComment", { comment: this.toAddComment, blogId: this.blogId }).then((resp) => {
        if (resp.data.code != 0) {
          this.$message.error("error:[" + resp.data.code + "]" + resp.data.msg);
        }
        this.queryComment();
        this.toAddComment = '';
      }).catch(e => {
        console.log(e);
        if (e.response && e.response.status == 401) {
          this.$message.error("This menu is private,please login")
          this.$parent.$parent.$parent.clickLogin()
          this.$refs.loginLink.click()
        } else {
          this.$message.error("system error:" + e);
        }
      });
    },
    handleDelete(row) {
      axios.delete("/api/comment/v1/delete?id=" + row.id).then((resp) => {
        if (resp.data.code != 0) {
          this.$message.error("error:[" + resp.data.code + "]" + resp.data.msg);
        }
        this.queryComment();
      }).catch(e => {
        console.log(e);
        if (e.response && e.response.status == 401) {
          this.$message.error("This menu is private,please login")
          this.$parent.$parent.$parent.clickLogin()
          this.$refs.loginLink.click()
        } else {
          this.$message.error("system error:" + e);
        }
        // this.dialogVisible=true;
      });
    },
    formatDate: function (apiDateStr) {
      return DateUtils.formatDate(apiDateStr);
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bg-purple-dark {
  background: #99a9bf;
}
</style>