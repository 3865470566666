<template>
  <div>
    <div v-if="editFlag">
      <el-input v-model="title" placeholder="blog title" v-if="editFlag"></el-input>
      <el-row type="flex" justify="end">
        <el-button type="primary" @click="sendBolg" style="{float:right}" plain>save</el-button>
      </el-row>
      <mavon-editor v-model="homeBlogContent" :toolbars="toolbars" :subfield="true" />
    </div>
    <div v-if="!editFlag">
      <el-row type="flex" justify="end" v-if="isAdmin">
        <el-button type="primary" @click="editFlag = true" plain>edit</el-button>
      </el-row>
      <el-row type="flex" justify="start" v-if="isAdmin">
        <h1>{{ title }}</h1>
      </el-row>

      <mavon-editor v-model="homeBlogContent" :toolbarsFlag="false" :subfield="false" defaultOpen="preview"
        :editable="false" />

    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "HomePage",
  data() {
    return {
      editData: '',//输入的数据
      viewData: '',//输入的数据
      homeBlogContent: '',
      rawHtml: '',
      editFlag: false,
      isAdmin: false,
      blogId: null,
      title: '',
      toolbars: {
        bold: true, // 粗体
        italic: true, // 斜体
        header: true, // 标题
        underline: true, // 下划线
        mark: true, // 标记
        superscript: true, // 上角标
        quote: true, // 引用
        ol: true, // 有序列表
        link: true, // 链接
        imagelink: true, // 图片链接
        help: true, // 帮助
        code: true, // code
        subfield: true, // 是否需要分栏
        fullscreen: true, // 全屏编辑
        readmodel: true, // 沉浸式阅读
        undo: true, // 上一步
        trash: true, // 清空
        save: true, // 保存（触发events中的save事件）
        navigation: true // 导航目录
      }
    };
  },
  mounted() {
    const name = window.localStorage.getItem("userName")
    if (name == "wenbin.zheng") {
      this.isAdmin = true;
    }
    this.loadData()
  },
  methods: {
    sendBolg() {
      console.log(this.editData)
      this.viewData = this.editData
      this.editFlag = false
      if (this.blogId) {
        axios.post("/api/blog/v1/update", { content: this.homeBlogContent, title: this.title, id: this.blogId }).then((resp) => {
          if (resp.data.code != 0) {
            this.$message.error("error:[" + resp.data.code + "]" + resp.data.msg);
          }
        }).catch(e => {
          console.log(e);
          if (e.response && e.response.status == 401) {
            this.$message.error("This menu is private,please login")
            this.$parent.$parent.$parent.clickLogin()
            this.$refs.loginLink.click()
          } else {
            this.$message.error("system error:" + e);
          }
        });
      } else {
        axios.post("/api/blog/v1/add", { content: this.homeBlogContent, title: this.title }).then((resp) => {
          if (resp.data.code != 0) {
            this.$message.error("error:[" + resp.data.code + "]" + resp.data.msg);
          }
        }).catch(e => {
          console.log(e);
          if (e.response && e.response.status == 401) {
            this.$message.error("This menu is private,please login")
            this.$parent.$parent.$parent.clickLogin()
            this.$refs.loginLink.click()
          } else {
            this.$message.error("system error:" + e);
          }
        });
      }


    },
    loadData() {
      this.blogId = this.$route.query.blogId;
      if (!this.blogId) {
        this.editFlag = true;
        return;
      }
      axios.get("/api/blog/v1/detail?id=" + this.blogId).then((resp) => {
        if (resp.data.code == 0) {
          this.homeBlogContent = resp.data.data.content;
          this.title = resp.data.data.title;
          this.blogId = resp.data.data.id;
        } else {
          this.$message.error("error:[" + resp.data.code + "]" + resp.data.msg);
        }
      }).catch(e => {
        console.log(e);
        if (e.response && e.response.status == 401) {
          this.$message.error("This menu is private,please login")
          this.$parent.$parent.$parent.clickLogin()
          this.$refs.loginLink.click()
        } else {
          this.$message.error("system error:" + e);
        }
        // this.dialogVisible=true;
      });
    }

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bg-purple-dark {
  background: #99a9bf;
}
</style>